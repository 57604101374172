import cookien from "../cookie"
import IMask from "imask"
import accountableFilter from "./accountableFilter"
import reInitForms from '../reInitForms'
import ordersFilterSettings from "./ordersFilterSettings"
import ownerSearch from "./ownerSearch"

export class OrdersFilter
{
	inited = false
	additionalData = {}
	$radioStatus = $('form[name="form_orders_filter_statuses"] [name="orders-status"][type="radio"]')
	init() {
		this.inited = true
		const _this = this

		const view = cookien.getCookie('orders_view') || 'orders'
		$('#orders_tab .c-sort-v__i').removeClass('active')
		$(`[data-view="${view}"]`).addClass('active')
		const element = document.querySelector('.js-input-number-order')
		if (element) {
			$(element).keypress(function () {
				return $(this).val().length <= 10
			})

			IMask(element, {
				mask: [
					{
						mask: 'ПК000000000',
					},
					{
						mask: 'ПК{-}00000000',
					},
				],
			})
		}

		if ($('#lk-suggestion-input').length) {
			ownerSearch.init()
		}
		if ($('[name="ordersFilterSettings"]').length) {
			ordersFilterSettings.init()
		}

		if ($('[name="filter[accountable]"]').length) {
			accountableFilter.init()
		}

		$(document).on('click', '#orders_tab .c-sort-v__i', function () {
			const tab = $(this)
			const container = $('#orders_container')
			const order_view = tab.data('view')
			if (tab.hasClass('active')) {
				return
			}
			$('#orders_tab .c-sort-v__i').removeClass('active')
			tab.addClass('active')
			container.addClass('is-load')
			cookien.setCookie('orders_view', tab.data('view'))
			$.ajax({
				url: location.href,
				data: {
					order_view,
				},
				success(html) {
					$('.history-filter__body').html($(html).find('.history-filter__body').html())
					$('.history-filter__loader').html($(html).find('.history-filter__loader').html())
					$('.settings__container').html($(html).find('.settings__container').html())
					if(!$(html).find('form[name="form_orders_filter_statuses"]').length) {
						$('form[name="form_orders_filter_statuses"]').remove()
					} else {
						$('form[name="form_orders_filter"]')
							.after($(html).find('form[name="form_orders_filter_statuses"]'))
					}
					$('.history-filter').toggleClass('history-filter__shop-orders')
					container.removeClass('is-load')
					reInitForms.run()
					if (order_view === 'orders') {
						ownerSearch.init()
						ordersFilterSettings.init()
						accountableFilter.init()
					}
				},
			})
		})

		$(document).on('submit', 'form[name="form_orders_filter"]', function (evt) {
			evt.preventDefault()
			_this.submitHandler()
		})
		$(document).on('click', '[data-orders-sort-btn]', function (evt) {
			_this.sortHandler({
				evt,
			})
		})
		$(document).on('reset', 'form[name="form_orders_filter"]', function () {
			const multipleSelect = $('select[multiple]')
			$('select').each(function (i, el) {
				if ($(el).find('[data-default-option]').length) {
					$(el).find('[data-default-option]').prop('selected', true)
					$(el).data('selectric').refresh()
				}
			})
			$('.history-filter__item [data-datepicker-range] input').each(function (i, el) {
				$(el).data('datepicker').clear()
				$(el).trigger('change')
			})
			if (multipleSelect.length > 0) {
				multipleSelect.val([])
				multipleSelect.data('selectric').refresh()
			}
			$('[name=orders-filter]').prop('checked', false)
			$('[name=orders-filter]:first').prop('checked', true)
			$('[name="orders-status"][value="ACTIVE"]').prop('checked', true)
			if (window.App.ownerSearch.inited) {
				window.App.ownerSearch.refresh()
			}
			_this.getOrders({})
		})

		$(document).on('change', 'form[name="form_orders_filter_statuses"]', () => {
			if ($('form[name="form_orders_filter"]').length) {
				_this.submitHandler()
			} else {
				const data = {STATUS: $('input[name="orders-status"]:checked').val()}
				_this.getOrders(Object.assign(data, _this.additionalData))
			}
		})
	}
	submitHandler() {
		let data = {}
		const formData = $('[name="form_orders_filter"]').serializeArray()

		$.each(formData, function () {
			if (this.value && this.value !== 'default') {
				if (data[this.name]) {
					if (!data[this.name].push) {
						data[this.name] = [data[this.name]]
					}
					data[this.name].push(this.value)
				} else {
					data[this.name] = this.value
				}
			}
		})
		if (this.$radioStatus.length) {
			data['STATUS'] = $('[name="orders-status"]:checked').val()
		}
		['time', 'booked_to', 'cruise_start_date', 'cruise_end_date', 'cruise_departure_at', 'cruise_arrival_at']
			.filter(code => !!data[`filter[${code}][]`])
			.forEach(code => {
				data[`filter[${code}][]`] = data[`filter[${code}][]`].split(',')
			})
		data = Object.assign(data, this.additionalData)
		this.getOrders(data)
	}
	getOrders(data) {
		const self = this

		$('.history-filter__loader').addClass('is-load')
		this.disabledOrdersStatus()

		$.ajax({
			url: location.pathname,
			data,
			success(html) {
				$('.js-orders-list').html(html)
			},
			error(er) {
				if (er.status === 403) {
					location.reload()
				}
			},
		}).done(function () {
			$('.history-filter__loader').removeClass('is-load')
			self.enabledOrdersStatus()
		})
	}
	disabledOrdersStatus() {
		this.$radioStatus.closest('.b-checkbox__label').addClass('disabled')
	}
	enabledOrdersStatus() {
		this.$radioStatus.closest('.b-checkbox__label').removeClass('disabled')
	}
	sortHandler(props) {
		const orderByValue = $(props.evt.currentTarget).attr('data-order-by')
		const directionValue = $(props.evt.currentTarget).attr('data-order-direction')

		this.additionalData['orderBy'] = orderByValue
		this.additionalData['orderDirection'] = directionValue

		this.toggleSortDirection({
			$btn: $(props.evt.currentTarget),
		})

		if ($('form[name="form_orders_filter"]').length) {
			this.submitHandler()
		} else {
			this.getOrders(this.additionalData)
		}
	}
	toggleSortDirection(props) {
		const curDirection = props.$btn.attr('data-order-direction')

		if (curDirection === 'asc') {
			props.$btn.attr('data-order-direction', 'desc')
		} else if (curDirection === 'desc') {
			props.$btn.attr('data-order-direction', 'asc')
		}
	}
}
