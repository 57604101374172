import crsOrder from "./rest/crs/crsOrder";
import crsUser from "./rest/crs/crsUser";

const vodohodPro = {
    async createOrder(add_id) {
		const customer_id = (await crsUser.getCurrent()).data.id
		const order = await crsOrder.shopOrdersAdd({
			add_id,
			customer_id
		})
        if (+order.data.order_id > 0) {
            await crsOrder.shopOrdersConfirm(+order.data.order_id)
            return (await crsOrder.shopOrdersGet(order.data.order_id)).data
        }
        return {}
    },
}

module.exports = vodohodPro
