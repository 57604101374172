import {CrsClient} from "../crs-client";
const cookie = require("../../cookie");
const {conversionJsonToFormData} = require("../../vue/basket/utils/core/conversionJsonToFormData");

class CrsOrder extends CrsClient {
    cartsCalculate(data) {
        const uuid = cookie.getCookie("mindboxDeviceUUID")
        if (uuid !== undefined) {
            data["uuid"] = uuid
        }
        return this.service.post(`/v1/carts/calculate`, conversionJsonToFormData(data))
    }

    ordersFormalize(orderId, data = {}) {
        const uuid = cookie.getCookie("mindboxDeviceUUID")
        if (uuid !== undefined) {
            data["uuid"] = uuid
        }
        return this.service.post(`/orders/formalize/${orderId}`, conversionJsonToFormData(data))
    }

    approveDiscount(id, discount) {
        return this.service.post(`/orders/approve-discount/${id}`,
            conversionJsonToFormData({discount}))
    }

    bookRoom(data) {
        const clientID = cookie.getCookie("_ym_uid")
        if (clientID !== undefined) {
            data["clientID"] = clientID
        }
        return this.service.post(`/orders/book-room`, conversionJsonToFormData(data), {withCredentials: true})
    }

    sellerComment(id, seller_comment) {
        return this.service.post(`/orders/seller-comment?id=${id}`, seller_comment)
    }

    setCancellationTermsText(id, text) {
        return this.service.post(`/orders/set-cancellation-terms-text?id=${id}`, {text})
    }

    contractChange(id, newContract) {
        return this.service.post(`/orders/contract-change?id=${id}`, {newContract})
    }

    setPkDiscountDenied(id, enabled) {
        return this.service.post(`/orders/set-pk-discount-denied?id=${id}`, {enabled})
    }

    unregisterInternalDiscounts(order, internalDiscountProhibition) {
        return this.service.post("/orders/unregister-internal-discounts", {order, internalDiscountProhibition})
    }

    discountProhibitionOff(order) {
        return this.service.post("/orders/discount-prohibition-off", {order})
    }

    unregisterManualDiscount(order) {
        return this.service.post("/orders/unregister-manual-discount", conversionJsonToFormData({order}))
    }

    registerManualDiscount(order, discountName, discountValue) {
        return this.service.post("/orders/register-manual-discount", conversionJsonToFormData({
            order,
            discountName,
            discountValue
        }))
    }

    changeManagerVerificationStatus(order, value) {
        return this.service.post(`/orders/change-manager-verification-status`, conversionJsonToFormData({order, value}))
    }

    canPay(id) {
        return this.service.get(`/orders/can-pay/${id}`)
    }

    getDetails(id) {
        return this.service.get(`/orders/${id}/details`)
    }

    getPaymentLink(id) {
        return this.service.get(`/orders/get-payment-link/${id}`)
    }

    extendReserve(id, toDate) {
        return this.service.post(`/orders/extend-reserve/${id}`, conversionJsonToFormData({toDate}))
    }

    addDiscount(id, discount) {
        return this.service.post(`/orders/add-discount/${id}`, conversionJsonToFormData({discount}))
    }

    removeDiscount(id) {
        return this.service.post(`/orders/remove-discount/${id}`)
    }

    cancel(id) {
        return this.service.post(`/orders/cancel/${id}`)
    }

    resurrectOrder(order) {
        return this.service.post("/orders/resurrect-order", conversionJsonToFormData({order}))
    }

    getList(params) {
        return this.service.get("/orders/list", {params})
    }

    transferMoney(data) {
        return this.service.post(`/orders/transfer-money`, data)
    }

    appliableContracts(id) {
        return this.service.get(`/orders/${id}/appliable-contracts`)
    }

    shopOrdersAdd(data) {
        return this.service.post('/shop/orders', data)
    }

    shopOrdersConfirm(id) {
        return this.service.post(`/shop/orders/${id}/confirm`)
    }

    shopOrdersGet(id) {
        return this.service.get(`/shop/orders/${id}`)
    }

    paymentMethods(id) {
        return this.service.get(`/orders/payment-methods?id=${id}`)
    }

    setPaymentMethod(order, payment_method, success_url = window.location.href + '&ending=1', fail_url = window.location.href, full_price) {
        return this.service.post('/orders/set-payment-method', conversionJsonToFormData({order, payment_method, success_url, fail_url, full_price: full_price}))
    }

    getSchedule(order_id, params = {}) {
        params['order_id'] = order_id
        return this.service.get('/payments/get-schedule', {params})
    }

    generateOrderContract(id) {
        return this.service.post(`/orders/${id}/generate-order-contract`)
    }

	setOrderComment(id, type, comment = '') {
		return this.service.put(`/orders/${id}/comments`, {type, comment})
	}

	uploadTicket(passengerId, file) {
		return this.service.post(`/passengers/${passengerId}/upload-ticket`,
			{file: file})
	}
    getGisEpOrders(orderId, params = []) {
        const expandParam = params.length > 0 ? `?expand=${params.join(',')}` : '';
        return this.service.get(`/orders/${orderId}/gis-ep-orders${expandParam}`);
    }
    
    getOneGisEpOrder(orderId, cabinId, expand = []) {
        const expandParam = expand.length > 0 ? `?expand=${expand.join(',')}` : '';
        return this.service.get(`/orders/${orderId}/gis-ep-orders/${cabinId}${expandParam}`);
    }
    createOneGisEpOrder(orderId, data) {
        return this.service.post(`/orders/${orderId}/gis-ep-orders`, data)
    }
    updateOneGisEpOrder(orderId, gisOrderId, data) {
        return this.service.put(`/orders/${orderId}/gis-ep-orders/${gisOrderId}`, data)
    }
}

const crsOrder = new CrsOrder()
export default crsOrder
